import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader/servicesheader'
import ServicesArea from '../containers/index-appointment/services-area'
import SolutionsArea from '../containers/about-us/solutions-area'
import ContactArea from '../containers/index-appointment/contact-area'
import CTAArea from '../containers/global/cta-area/section-two'


const ITServicePage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO 
      title="Services"
      description="Through our services framework and innovative approach of Consult, Design, Engineer, Operate and Optimize, our teams of experts deliver powerful digital experiences."
    />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Services"
    />
    <main className="site-wrapper-reveal">
        <ServicesArea/>
        <CTAArea/> 
        <SolutionsArea />
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default ITServicePage
 